import styles from './preview.module.scss';
import MainButton from '../../../button/MainButton';
import {ReactComponent as BoltGreen} from '../../../../assets/svg/bolt-green.svg';
import {ReactComponent as InfoIcon} from '../../../../assets/svg/info-icon.svg';
// import {ReactComponent as EyeIcon} from '../../../../assets/svg/eye.svg';
import {formatPrice, valueExists} from '../../../../utils/utils';
import {useEffect, useMemo, useState} from 'react';
import Loading from '../../../status/loading';
import ErrorModalScreen from '../../shared/common/ErrorModalScreen';
import clsx from 'clsx';

const Preview = ({
	provider,
	targetInfo,
	availableAccounts,
	fiatAmount,
	fiatCurrency,
	setExchangeAccountId,
	setNetwork,
	setValueInfo,
	handleNextStep,
	canProceed,
	loading,
	error
}) => {
	const paymentOptions = useMemo(() => {
		return Object.entries(availableAccounts).flatMap(([accountId, value]) => {
			const networkOptions = value.network_options ?? [];
			return networkOptions.map(networkOption => ({
				accountId,
				network: networkOption?.name,
				currency: value?.currency,
				currencyName: value?.currency_name,
				currencySymbol: value?.currency_symbol,
				minAmount: networkOption?.min_withdrawal_amount ?? 0,
				exchangeRate: networkOption?.exchange_rate,
				fundingBalanceSufficient: networkOption?.funding_balance_sufficient,
				networkFee: Math.max(
					(networkOption?.min_withdrawal_fee ?? 0) / (networkOption?.exchange_rate ?? 1),
					(networkOption?.fee?.fee_crypto ?? 0) / (networkOption?.fee?.exchange_rate ?? 1)
				),
				value: value?.value,
				isDisabled: (value?.value ?? 0) < (networkOption?.min_withdrawal_amount ?? 0),
			}))
				.toSorted((a, b) => a.isDisabled ? 1 : -1);
		});
	}, [availableAccounts]);

	const [selectedPaymentOption, setSelectedPaymentOption] = useState();
	const someOptionIsAvailable = useMemo(
		() => paymentOptions.some(option => !option.isDisabled),
		[paymentOptions]
	);
	const [checkboxesChecked, setCheckboxesChecked] = useState(false);
	const displayCheckbox = useMemo(() => !selectedPaymentOption?.fundingBalanceSufficient, [selectedPaymentOption]);

	useEffect(() => {
		if (selectedPaymentOption === undefined && paymentOptions.length > 0) {
			setSelectedPaymentOption(paymentOptions[0]);
		}
	}, [paymentOptions]);

	const handleExchangeAccountChange = (e) => {
		const index = e.target.value;
		setSelectedPaymentOption(paymentOptions[index]);
	};

	const handleCheckboxChange = (e) => {
		setCheckboxesChecked(prevCheckboxChecked => !prevCheckboxChecked);
	};

	const handleSubmit = () => {
		setValueInfo({
			value: selectedPaymentOption.value,
			currency: selectedPaymentOption.currencyName,
			converted_value: fiatAmount,
			converted_currency: fiatCurrency,
		});
		setExchangeAccountId(selectedPaymentOption.accountId);
		setNetwork(selectedPaymentOption.network);
		handleNextStep();
	};

	if (loading) {
		return <div className={styles.loadingContainer}>
			<Loading/>
		</div>;
	}

	if (valueExists(error)) {
		return <ErrorModalScreen message={error}/>;
	}

	return <div className={styles.container}>
		<div className={styles.topSection}>
			<div className={styles.heading}>
        Preview
			</div>
			<div className={styles.summaryContainer}>

				<div className={styles.summaryLineContainer}>
					<div className={styles.rowTitle}>
            From
					</div>
					<div className={styles.providerContainer}>
						<div className={styles.iconContainer}>
							<img src={provider?.icon} alt={provider?.description}/>
						</div>
						<div>
							{provider?.description}
						</div>
					</div>
				</div>
				<div className={styles.divider}></div>

				<div className={styles.summaryLineContainer}>
					<div className={styles.rowTitle}>
            To
					</div>
					<div className={styles.providerContainer}>
						<div className={styles.iconContainer}>
							<img src={targetInfo?.icon} alt={targetInfo?.description}/>
						</div>
						<div>
							{targetInfo?.description}
						</div>
						{/*<div>*/}
						{/*	({shortenString(targetInfo?.address, 12)})*/}
						{/*</div>*/}
						{/*<div className={styles.purpleIcon}>*/}
						{/*	<EyeIcon/>*/}
						{/*</div>*/}
					</div>
				</div>
				<div className={styles.divider}></div>

				<div className={styles.summaryLineContainer}>
					<div className={styles.rowTitle}>
            Funding method
					</div>
					<div className={styles.radioOptions}>
						{paymentOptions.map((paymentOption, index) =>
							<div className={styles.optionContainer} key={`${paymentOption.accountId}/${paymentOptions.network}`}>
								<div className={styles.radioItemContainer}>
									<input
										type='radio'
										name='balance-radios'
										value={index}
										checked={
											selectedPaymentOption?.accountId === paymentOption.accountId &&
                      selectedPaymentOption?.network === paymentOption.network
										}
										onChange={handleExchangeAccountChange}
										disabled={paymentOption.isDisabled}
									/>
									<div className={clsx(paymentOption.isDisabled && styles.disabled)}>
										{paymentOption.currency} ({paymentOption.network} network)
									</div>
								</div>
								{paymentOption.isDisabled && <div className={styles.minAmountWarning}>
									<div className={styles.icon}>
										<InfoIcon/>
									</div>
									<div className={styles.minAmountText}>
                    Min deposit: {formatPrice(paymentOption.minAmount / paymentOption.exchangeRate, fiatCurrency, 2)}
									</div>
								</div>}
							</div>
						)}
					</div>
				</div>
				<div className={styles.divider}></div>

				<div className={styles.totalLineContainer}>
					<div>
            Total
					</div>
					<div>
						{(selectedPaymentOption?.value ?? 0).toFixed(6)} {selectedPaymentOption?.currencyName} ≈ {formatPrice(fiatAmount, fiatCurrency, 2)}
					</div>
				</div>
			</div>
		</div>

		<div className={styles.bottomSection}>
			<div className={styles.feesHeading}>
        All fees included
			</div>

			<div className={styles.feeInfoLineContainer}>
				<div>
					{(selectedPaymentOption?.value ?? 0).toFixed(6)}{' '}
					{selectedPaymentOption?.currencyName} @ {formatPrice(1 / selectedPaymentOption?.exchangeRate, fiatCurrency, 2)}
				</div>
				<div>
					{formatPrice(fiatAmount, fiatCurrency, 2)}
				</div>
			</div>

			<div className={styles.feeInfoLineContainer}>
				<div>
          Network fee
				</div>
				<div>
					{/* We get fee in fiat currency, so no need for exchange rate in this case */}
					{typeof (selectedPaymentOption?.networkFee) === 'number'
						? `~${formatPrice(selectedPaymentOption.networkFee, fiatCurrency, 2)}`
						: 'Unknown'
					}
				</div>
			</div>

			{displayCheckbox && <div className={styles.feeInfoLineContainer}>
				<div className={styles.tradingAccountConfirmation}>
					<div className={styles.checkboxWithLabel}>
						<input type="checkbox" name="use-trading-balance" onChange={handleCheckboxChange} checked={checkboxesChecked}/>
						<label htmlFor="use-trading-balance">
              Also use trading account balance, as your funding account balance is not sufficient.
						</label>
					</div>
				</div>
			</div>}

			<MainButton
				className='confirm-button'
				onClick={handleSubmit}
				label={someOptionIsAvailable ? 'Proceed' : 'No option available'}
				icon={<BoltGreen className='bolt-icon'/>}
				disabled={!canProceed || !someOptionIsAvailable || (displayCheckbox && !checkboxesChecked)}
			/>
		</div>
	</div>;
};

export default Preview;